import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { NextPage } from 'next';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { getSession, GetSessionParams, SessionProvider } from 'next-auth/react';
import { ReactElement, ReactNode } from 'react';

import { Session } from '@/types/Session';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  session: Session;
};

export default function MyApp({ Component, pageProps: { ...pageProps }, session }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const pageLayout = getLayout(<Component {...pageProps} />);

  return (
    <>
      <Head>
        <title>クレド | 株式会社メディロム</title>
      </Head>
      <SessionProvider
        session={session}
        refetchInterval={0}
      >
        <QueryClientProvider client={queryClient}>
          <ChakraProvider>{pageLayout}</ChakraProvider>
        </QueryClientProvider>
      </SessionProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const session = await getSession(appContext as GetSessionParams);

  // sessionが無い時の閲覧可能ページは'/login'だけ
  if (!session && appContext.ctx.pathname !== '/login') {
    appContext.ctx.res?.writeHead(302, { Location: '/login' });
    appContext.ctx.res?.end();
  }

  // ログイン済みで'/login'に行こうとしたらトップ画面にリダイレクトさせる
  if (session && appContext.ctx.pathname === '/login') {
    appContext.ctx.res?.writeHead(302, { Location: '/' });
    appContext.ctx.res?.end();
  }

  // userで管理画面に行こうとしたらリダイレクトさせる。
  if (session && (session as Session).user?.role === 'user' && appContext.ctx.pathname.indexOf('/admin') === 0) {
    appContext.ctx.res?.writeHead(302, { Location: '/' });
    appContext.ctx.res?.end();
  }

  return { ...appProps, session };
};
